 @import "/public/assets/style/index.scss";

 .page-content {
     width: 85%;
 }

 .wrapper {
     display: flex;
     justify-content: center;
     padding: 0 10px;
     width: 100%;
     padding: 50px 0;
 }

 .page-title {
     color: #fff;
     font-size: 28px;
     font-weight: 700;
 }

 .content {
     display: flex;
     gap: 10px;
     justify-content: center;
     flex-wrap: wrap;

     .single-application {
         min-height: 346px;
         width: 500px;
         padding: 15px 10px;
         display: flex;
         flex-direction: column;
        //  justify-content: space-between;
         align-items: center;
         margin:0 auto;
         line-height: 34px;

         .title {
             text-align: center;
             color: #fff;
         }

         .description {
             color: #fff;
         }

         .download-btn {
             padding: 5px 15px;
             font-weight: 700;
             background-color: #fff;
             color: $primary-dark;
             border-radius: 2px;
             margin-top: 10px;
             height: 38px;
             display: flex;
             align-items: center;
         }
     }
 }

 .os {
     font-size: 30px;
     color: #fff;
 }

 @media screen and (max-width:1430px) {
    //  .content {
    //      display: grid;
    //      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    //      justify-content: center;
    //      gap: 20px;
    //  }
 }