@import "../../../public/assets/style/mixin.scss";

.notfound-page {
  width: 100%;
  @include flexible("", "", center);
  gap: 20px;
  margin: auto;

  &_img {
    background-image: url("../../images/not-found.png");
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 44%;
    height: auto;
    margin-top: 100px;
  }

  &_first-block {
    @include flexible(column, center, center);

    &_img {
      @include flexible("", center, center);
      max-width: 100%;
      width: 30vw;
      height: auto;
    }

    &_txt {
      text-align: center;
      font-size: 18px;
      color: #fff;
    }
  }

  &_button {
    background-color: #25f662;
    color: #ffffff;
    border: transparent;
    padding: 15px 30px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    @include flexible("", center, center);
  }
}

@media screen and (max-width: 800px) {
  .notfound-page {
    flex-direction: column;
    align-items: center;

    &_first-block {
      &_txt {
        font-size: 10px;
      }
    }

    &_button {
      height: 10px;
      font-size: 10px;
      font-weight: 500;
    }

    &_img {
      margin-top: 30px;
      height: 200px;
      width: 56%;
    }
  }
}
