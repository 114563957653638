@import "/public/assets/style/index.scss";


.form {
    width: 350px;
    @include flexible(column, center, center);

    .form-content {
        width: 100%;
    }
}

.login-multiple-device {
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 100px;
}

.link {
    margin: 20px 0;
}

@media screen and (max-width:700px) {
    .login-multiple-device {
        font-weight: 500;
        font-size: 35px;
    }
}

@media screen and (max-width:520px) {
    .form {
        width: 250px;
    }   
}