@import "/public/assets/style/index.scss";

.add-btn {
    padding: 13px 29px;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 20px;
    background-color: $navbar_background_color ;
    border-radius: 4px;
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.devices-page-top {
    @include flexible("", center, space-between);
}

.title {
    font-weight: 700;
    font-size: 38px;
    color: #ffffff;
}

.device {
    @include flexible('', center, space-between);
    background-color: $navbar_background_color;
    border-radius: 4px;
    margin: 16px 0;
    padding: 10px 32px;
    width: 100%;
    color: #fff;

    .device_info {
        margin-bottom: 0;
    }

    .btn {
        cursor: pointer;
    }

    .login-btn {
        color: #fff;
        font-size: 20px;
        font-weight: 700;
    }
}

.code-description,
.modal-desc-text {
    color: #ffffff;
    font-size: 16px;
}

.key_or_code-modal-title {
    color: #ffffff;
    text-align: center;
}

.btn-container {
    width: 100%;
    @include flexible('', center, center);
    margin-top: 20px;
}

.more-button {
    svg {
        font-size: 23px;
    }

    cursor: pointer;
}

.menu{
    width: 100px;

    ul{
        background-color: $navbar_background_color ;

        li{
            color: #fff;

            &:hover{
                background-color: rgba(0, 0, 0, 0.25)
            }
        }
    }
}

@media screen and (max-width:700px) {
    .devices-page-top {
        @include flexible(column, 'flex-start', space-between);
    }

    .title {
        font-size: 28px;
    }
}