.tab-bar {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;

    .item {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        background: transparent;
        border: 1px solid white;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background-color: rgba(255, 255, 255, 0.391);
        }
    }

    .dont-hover {
        background: none !important;
        cursor: no-drop;
    }

    .active {
        background: white !important;
        color: #073e4a;
    }
}
