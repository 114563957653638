@import './variables.scss';


@mixin flexible($fd:"",$ai:"",$jc:"") {
    display: flex;
    flex-direction:unquote($fd) ;
    align-items:unquote($ai) ;
    justify-content:unquote($jc) ;
}

@mixin backgroundImage($url){
    background-image: url($url);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}