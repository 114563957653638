.glass-effect-wrapper {
    // min-height: 465px;
    background-color: rgba(103, 101, 113, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    overflow: hidden;
    padding: 75px 72px;
}

@media screen and (max-width:520px) {
    .glass-effect-wrapper {
        padding:15vw 10vw !important;
    }
}