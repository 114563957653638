.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stripe-payment-form {
  width: 350px;

  .stripe-submit-button {
    background: #f7c21a;
    color: #ffffff;
    border: none;
    padding: 14px 39px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21.79px;
    display: block;
    margin: 37px auto 0;
    cursor: pointer;
  }
}

@media screen and (max-width: 520px) {


  .stripe-payment-form {
    width: 245px;
  }

  .stripe-submit-button {
   height: 37px !important;
     display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 39px !important;
  }
}