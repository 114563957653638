@import "public/assets/style/variables.scss";

.activate-device-modal {
    .title {
        color:white ;
        text-align: center;
    }

    button {
        background-color: $app;
        color: white !important;

        &:hover {
            opacity: 0.8;
            background-color: $app;
            color: white !important;
            
        }

        &:focus {
            background-color: $app;
            color: white !important;
        }
    }
}

// .activate-device-modal .ant-input {
//     color:white !important;
// }