@import "../../styles/colors.scss";
@import "public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";

.activation-form-style {
    min-height: 400px;
    // width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;

    a {
        color: $active-color;
    }
}

.activate-device {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    justify-content: flex-end;

    section {
        display: flex;
        align-items: center;
        width: 360px;
        opacity: 0.5;

        .text {
            text-align: end;
            margin: 0;
        }

        button {
            border-radius: 7px;
            margin-left: 20px;
            background-color: #d6d9da54;
            border-color: transparent;
            color: white;

            &:hover {
                background-color: #d6d9da54;
                border-color: transparent;
                color: white;
            }
        }
    }
}

.packages-list {
    margin-top: 20px;
    // width: 640px;
    // display: grid;
    // // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // grid-template-columns: 1fr 1fr 1fr;
    // align-items: center;
    // justify-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-height: 40vh;
}

.package {
    width: 173px;
    height: 230px;
    background-image: url("../../images/package_bg.png");
    background-size: cover;
    background-repeat: no-repeat;

    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    text-align: center;
    color: #073e4a;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background-image: url("../../images/package_hover_bg.png");
    }

    .price {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        font-size: 16px;
        gap: 5px;
        font-weight: bold;
    }

    .name {
        font-size: 20px;
        font-weight: 700;
        line-height: 0.9;
        width: 145px;
        word-wrap: break-word;
    }

    .schedule-time {
        font-size: 20px;
    }

    .recomended {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 16px;
    }
}
.package.active {
    background: linear-gradient(152.86deg, $active-color, #5aa0af 100%);
    // margin-bottom: 40px;
    // height: 222px;
}
.activation-step2 {
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50vh;

    .checkboxes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .ant-checkbox-wrapper {
            margin: 0 !important;
            margin-top: 15px !important;
            font-size: 17px;
            a {
                color: white !important;

                &:hover {
                    color: $blue !important;
                }
            }
        }
    }

    .warrning-text {
        width: 280px;
        text-align: center;
        color: #9d9191 !important;
        margin-bottom: 40px;
    }
}

.activation-step3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .email-mac-address {
        width: 300px;
        margin-top: 70px;
    }
}

.activation-step4 {
    width: 770px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 50vh;

    section {
        display: flex;
        width: 100%;
        margin-top: 40px;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        width: 50%;
        border-right: 2px solid white;
        padding-right: 20px;
        padding: 20px;
        gap: 15px;

        .button {
            width: 100% !important;
            padding: 10px;
            background-color: transparent;
            text-align: center;
            border-radius: 5px;
            font-size: 20px;
            font-weight: 600;
            color: $text;
            border: 2px solid $text;
            background-color: transparent;
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                opacity: 0.9;
            }

            &.active {
                background-color: $text;
                color: $app;
            }
        }
    }

    .container {
        width: 59%;
        height: 100%;
        padding: 20px;
    }
}

.stripe-paypal-desibled-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-top: 20px;
        font-size: 20px;
        color: #fd7072 !important;
    }
}

.agree-with-all-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $active-color;
    border-color: $active-color;
}
.coupon-activation-activate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

@media screen and (max-width: 772px) {
    .packages-list {
        // grid-template-columns: 1fr;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .package-list_title {
        font-size: 25px !important;
    }
}
