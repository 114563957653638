@import "../../../../public/assets/style/mixin.scss";

.page {
  @include flexible("", center, center);

  .login-by-mac-page {
    margin: 100px 0 20px 0;
    padding: 95px 72px;
    background: rgba(103, 101, 113, 0.34);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    @include flexible(column, center, center);

    &__title {
      font-weight: 700;
      font-size: 48px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 100px;
    }

    &__form {
      width: 350px;
      @include flexible(column, center, "");
    }

    &__form__btn {
      text-align: center;
      margin-top: 70px;
      cursor: pointer;

      button {
        width: max-content;
        cursor: pointer;
      }
    }

   
  }
}

 .description{
      color: #fff;
      margin: 16px;
      font-size: 16px;
    }
    
@media screen and (max-width: 520px) {
    .login-by-mac-page{
        padding:15vw 10vw !important;

        &__title {
            font-size: 36px !important;
        }

        &__form{
            width: 70vw !important;
        }

        &__form__btn {

            button{
                min-width: max-content !important;
            }
        }
    }
}
