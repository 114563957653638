@import "../../../public/assets/style/variables.scss";

.news-card {
    // width: 444px;
    width: 385px;
    // height: 505px;
    height: 465px;
    padding: 24px 27px 38px 27px;
    background-color: $secondary-dark;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    .image {
        width: 100%;
        // height: 336px;
        height: 320px;
        object-fit: contain;
        margin-bottom: 16px;
    }

    .added-on {
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 14px;
        color: #CCCCCC;

        span {
            margin-left: 5px;
        }
    }

    .title {
        font-weight: 700;
        font-size: 30px;
        text-transform: capitalize;
        color: #FFFFFF;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}


// @media screen and (max-width: 1002px) {
//     .news-card {
//         background-color: red;
//         width: 200px;
//     }
// }

@media screen and (max-width: 772px) {
    .news-card {
        // width: 300px;
        width: 335px;
        height: 355px;
        margin: auto;
        padding: 12px 14px 20px 14px;

        .image {
            height: 250px;
        }

        .added-on {
            font-size: 12px;
        }

        .title {
            font-size: 24px;
        }
    }
}