@import "../../../styles/colors.scss";
@import "../../../../public/assets/style/variables.scss";
@import "../.././../../public/assets/style/mixin.scss";

.stripe-button {
    margin-top: 20px;
    font-size: 20px;
    height: 45px;
    border-radius: 5px;
}

.text,
.text-voucher {
    text-align: center;
    font-size: 20px;
    color: $title_color !important;
    margin-top: 23px;
}

.text-voucher {
    font-size: 13px;

}

.stripe-component-container {
    width: 100%;
    @include flexible(column, center, center);
}

.types-container {
    @include flexible("", center, center);
    gap: 33px;
    // margin: 19px 0 72px 0;

    .type {
        padding: 7px 26px;
        background: white;
        color: $active-color;
        width: max-content;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        transition: 0.3s;

        &.active {
            color: #ffffff;
            background: $active-color;
        }
    }
}

.warning-notification {
    text-align: center;
    color: #f84e4e;
    font-size: 17px;
    margin-top: 10px;
    font-weight: 600;
    text-transform: capitalize;
}

.activation-form-bottom {
    max-width: 500px;
    width: 100%;
}

.stripe-subscribe-button {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-top: 10px;
    width: 100%;
    height: 43px;
    border-radius: 5px;
    background-color: white;
    border-color: white;
    color: #635bff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        color: #6b95b1;
        background-color: rgb(229, 226, 226);
        border-color: rgb(229, 226, 226);
    }
}

.powered-by-stripe {
    margin-top: 10px;
    text-align: center;
    color: #359cdd;
    font-style: italic;
    font-size: 10px;
}

.block {
    min-height: 36rem;
}

.back-btn-container {
    @include flexible("", "", flex-start);
}

.back-btn {
    @include flexible("", center, center);
    padding: 0.7rem 3rem;
    border-radius: $border-radius;

    span {
        color: $btn-text-color !important;
        font-size: 1.5rem;
        font-weight: bold;
    }
}

.paypal-btn-container {
    transition: all 5ms ease-in-out;
    width: 100%;
}

.loading {
    min-height: 10vh;
    @include flexible("", center, center);
}

.error-text {
    display: block;
    color: #bf4342;
    font-size: 1.6rem !important;
}

.success-text {
    display: block;
    color: #8dec76;
    font-size: 1.6rem !important;
}

.open {
    height: 40px;
    transition: 0.2s;
}

.close {
    overflow: hidden;
    height: 0;
    transition: 0.2s;
}

.stripe-section {
    label {
        color: $text !important;
    }
}

.page-container {
    // width: 600px;
    min-height: 70vh;
    padding: 2rem 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.405);
    background-color: $secondary-dark;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
}

.packages-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 905px;
    margin-bottom: 20px;
    min-height: 122px;
}

.or:after {
    content: "";
    position: absolute;
    width: 43%;
    height: 0.5px;

    background: #ffffff27;
    transform: translate(4%, -50%);
    top: 50%;
}

.or:before {
    content: "";
    position: absolute;
    width: 43%;
    height: 0.5px;
    background: rgba(255, 255, 255, 0.152);

    transform: translate(-105%, -50%);
    top: 50%;
}

.or {
    margin-bottom: 15px;
    color: white;
    width: 100%;
    text-align: center;
    position: relative;
}

.pixi-payment-button,
.cmi-payment {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    cursor: pointer;
    text-align: center;
    background: white;
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 8px;
    color: red;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.tazapay,
.parcelado-usa,
.coinbase-button {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    margin-top: 10px;
    background-color: #014cec;
    color: #fff;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    font-weight: 500;
    font-size: 17px;
}

.parcelado-usa {
    background-color: #002776;
}

.tazapay {
    background-color: #103747;
}

.pixi-payment-button {
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #636363;
    color: #fff;
}

.pixi-email-container {
    min-width: 300px;
    width: 100%;

    .pixi-email-title {
        color: #fff;
        font-size: 23px;
        margin-bottom: 20px;
        text-align: center;

    }
}

.amazon-pay {
    width: 100%;
}

.button-container {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 400px) {
    .activation-form-bottom {
        padding: 0 30px !important;
    }

    .types-container {
        gap: 10px;
    }
}

@media screen and (max-width: 520px) {
    .activation-form-bottom {
        width: 350px;
    }
}

// .ant-form-item-explain-error {
//   font-style: italic !important;
//   float: right !important;
//   margin-top: 15px;
//   color: #f84e4e !important;
//   }