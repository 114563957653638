.phone-input-component {
    input {
        color: #fff !important;

        &::placeholder {
            color: #bbbb !important;
        }
    }

    .selected-flag {
        &:hover {
            background-color: transparent !important;
        }
    }
}