@import "../../../../public/assets/style/variables.scss";
@import "../../../../public/assets/style/mixin.scss";

.card.bg {
  // @include backgroundImage("../../../../public/assets/images/home/background.png");
}

.card {
  min-height: 60vh;

  .container {
    height: 100%;
    margin: auto;
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    max-width: 85%;
  }

  h1 {
    color: $title_color;
    font-size: 35px;
    margin-bottom: 100px;
  }

  p {
    font-size: 20px;
    color: $text-color !important;
  }

  .container.reverse {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 850px) {

  .container,
  .container.reverse {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 772px) {
  .container {
    width: 90%;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 720px) {
  .card {
    .container {
      height: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      padding: 40px 10px;
    }

    h1 {
      color: $title_color;
      font-size: 22px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      color: $text-color;
    }

    img {
      width: 100%;
    }
  }
}

.desc {
  // width: 600px;
}