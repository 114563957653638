.error-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    
    p {
        color: #ff4d4f !important;
        text-align: center;
    }

    button {
        &:hover {
            background-color: #ff4d4f !important;
            opacity: 0.8;
        }
    }
}

.success-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 40px;
    text-align: center;
    p {
        color: #1890ff !important;
    }

    button {
        color: white !important;
    }
}
