@import "../../../../public/assets/style/variables.scss";
@import "../../../styles/colors.scss";
@import "/public/assets/style/mixin.scss";

.payment-page-main {
  @include flexible("", center, center);
  min-height: calc(100vh - 80px);

  .warning-text {
    font-size: 15px;
    color: red;
    text-align: center;
  }

  .page-wrapper {
    margin: 100px 0 20px;
    padding: 2rem 7rem;
    @include flexible("", center, center);
    background: rgba(0, 0, 0, 0.405);
    background-color: $secondary-dark;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);

    .title {
      color: #ffffff;
      font-size: 20px;
      text-align: center;
    }

    .checked {
      position: absolute;
      right: 10px;
    }

    .package-style {
      margin: 35px 76px;
      background: #ffffff;
      border: 3px solid #25f662;
      color: #073e4a;
      border-radius: 6px;
      padding: 15px 20px;
      height: 200px;
      width: 173px;
      position: relative;
    }

    .package-text-style-currency,
    .package-text-style {
      font-weight: 700;
      font-size: 23px;
      margin-bottom: 0;
      word-wrap: break-word;
      margin-right: 5px;
    }

    .package-text-style-currency {
      font-size: 16px;
    }

    .package-price {
      @include flexible("", "baseline", center);
      position: relative;
      bottom: -33px;
    }

    .payment-page-container {
      @include flexible(column, center, center);
    }

    .privacy-policy-text {
      color: #ffffff;
      margin: 30px 0 90px;
      font-size: 16px;
      text-align: center;
    }

    .mac {
      color: #ffffff;
      font-size: 18px;
      display: block;
      margin: 28px 0 10px;
    }


  }
}

.coinbase-button {
  background-color: #014cec;
  color: #fff;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  font-weight: 500;
}

.ineco-button {
  // background-color: #14442b;
  background-color: white;
  color: black;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  font-weight: 500;
  margin: 10px 0;
}

.ineco-button img {
  width: 100px;
  margin: 0 10px;
}

.ineco-button span {
  font-size: 12px;
  font-weight: 400;
}

.mac-adress-input-contaner {
  @include flexible(column, center, center);
  width: 500px;

  &__text {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 100px;
  }

  .mac-input {
    width: 300px;
    margin-bottom: 70px;
  }

  .payment-icons {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
}

.or:after {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;

  background: rgba(255, 255, 255, 0.152);
  transform: translate(4%, -50%);
  top: 50%;
}

.or:before {
  content: "";
  position: absolute;
  width: 43%;
  height: 0.5px;
  background: rgba(255, 255, 255, 0.152);

  transform: translate(-105%, -50%);
  top: 50%;
}

.or {
  margin: 15px 0;
  color: white;
  width: 100%;
  text-align: center;
  position: relative;
}

.my-fatoorah-payment-button {
  margin-top: 10px;
  height: 43px;
  border-radius: 5px;

  background-color: #438eb9;
  color: #fff;

  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  img {
    width: 30px;
    height: auto;
  }
}

@media screen and (max-width: 740px) {
  .page-wrapper {
    padding: 2rem !important;

    .title {
      font-size: 16px !important;
    }
  }

  .mac-input {
    width: 270px !important;
    font-size: 14px;

    input::placeholder {
      font-size: 14px;
    }
  }

  .mac-adress-input-contaner {
    width: inherit !important;
    font-size: 16px !important;

    &__text {
      font-size: 16px !important;
    }
  }

  .payment {
    // width: 300px !important;
  }
}