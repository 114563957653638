@import "../../../../public/assets/style/variables.scss";
@import "../../../styles/colors.scss";

.contact-us_main {
    padding: 176px 0 166px;

    .contact-us_wrapper {
        padding: 122px 122px 111px 87px;
        // display: flex;
        justify-content: space-between;
        background-color: $secondary-dark;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(15px);

        .info-form_wrapper {
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }

        .info {
            width: 100%;
            .title {
                font-weight: 400;
                font-size: 48px;
                font-weight: 700;
                color: $title_color;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                text-transform: capitalize;

                span {
                    color: $primary-color;
                }
            }
        }

        form {
            padding: 82px 50px;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            border-radius: 5px;
            width: 100%;

            .title-input,
            .email-input,
            .message-input {
                border: 1px solid rgba(7, 64, 76, 0.3);
                background-color: #fff;
                border-radius: 5px;
                padding: 15px 15px 15px 25px;
                font-size: 15px;
                color: rgba(7, 64, 76, 0.6) !important;
            }

            .message-input {
                resize: none;
            }
        }
    }
}

.contact-us_info {
    .description {
        width: 80%;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        color: #ffffff;
    }

    .contact-list {
        list-style: none;

        a {
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            color: #fff !important;
            
            .icon {
                width: 90px;
                height: 90px;
            }

            .list-info {
                max-width: 220px;

                h3 {
                    font-weight: 600;
                    font-size: 20px;
                    color: $active-color;
                    margin-bottom: 5px;
                }

                p {
                    font-weight: 400;
                    font-size: 16px;
                    color: #ffffff;
                }
            }
        }
    }

    ul {
        padding: 0 !important;
    }
}

@media screen and (max-width: 1300px) {
    .contact-us_main {
        .contact-us_wrapper {
            padding: 50px 40px;

            form {
                padding: 50px 40px;
                margin-top: 50px;
                width: 100%;

                .title-input,
                .email-input,
                .message-input {
                    width: 100%;
                }
            }
        }
    }

    .contact-us_info {
        width: 100%;
        margin-top: 20px;
        .description {
            max-width: 90%;
        }
        .contact-list {
            a {
                .icon {
                    width: 70px;
                    height: 70px;
                }

                .list-info {
                    max-width: 200px;
                    h3 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .contact-us_main {
        padding: 130px 0 80px;
    }

    .info-form_wrapper {
        flex-direction: column-reverse;
        align-items: center;

        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)) !important;
    }

    .contact-us_main {
        .contact-us_wrapper {
            flex-direction: column;

            .info {
                width: 80%;
                // order: 2;

                .title {
                    font-size: 36px;
                    position: absolute;
                    top: 20px;
                    left: 0;
                    text-align: center;
                    width: 100%;
                }
            }

            form {
                margin-bottom: 20px;
                // order: 1;
                display: flex;
                width: 100%;
            }
        }
    }

    .contact-us_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contact-list {
            width: 95%;
            display: flex;
            justify-content: space-between;

            a {
                flex-direction: column;
                align-items: flex-start;
                max-width: 30%;
            }
        }
    }
}

@media screen and (max-width: 772px) {
    .contact-us_main {
        padding: 100px 0 80px;
        .contact-us_wrapper {
            .info {
                width: 90%;
                .title {
                    font-size: 28px;
                }
            }

            form {
                margin: 0;
                padding: 50px 20px;
                .title-input,
                .email-input,
                .message-input {
                    padding: 10px 10px 10px 15px;
                }
            }
        }
    }

    .contact-us_info {
        .description {
            width: 90%;
        }
        .contact-list {
            flex-direction: column;
            a {
                flex-direction: row;
                max-width: fit-content;
                .icon {
                    width: 60px;
                    height: 60px;
                    // margin-right: 20px;
                }

                .list-info {
                    max-width: 200px;

                    h3 {
                        // font-size: 16px;
                    }

                    p {
                        // font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .info-form_wrapper {
        flex-direction: column-reverse;
        align-items: center;

        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    }
    .contact-us_wrapper {
        padding: 20px 20px !important;

        form {
            width: 100% !important;
        }
    }

    .contact-us_info {
        .description {
            margin-top: 20px;
        }
    }
}
