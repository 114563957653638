@import "public/assets/style/index.scss";

.single-playlist {
    background: #091522;
    border-radius: 4px;
    @include flexible("", center, center);

    gap: 10px;
    margin-bottom: 16px;
    opacity: .7;
    padding: 18px 32px;

    &_container {
        width: 100%;
        @include flexible("", center, space-between);

        &_controller-btns {
            height: 21px;

            button {
                background-color: transparent;
                border: transparent;
                cursor: pointer;
            }

            @include flexible("", center, center);
        }

        &_url {
            color: #8d8e99;
            font-weight: 400;
            font-size: 20px;
            width: 720px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .name {
        font-weight: 600;
        font-size: 21px;
        color: #ffffff;
    }

    .playlist {
        font-weight: 300;
        font-size: 18px;
        color: $title_color;
        display: flex;
        align-items: center;
        gap: 50px;
    }


}

.playlist-add-btn {
    padding: 14px 39px;
    width: max-content;
    background: #091522;
    opacity: 0.7;
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
}

@media screen and (max-width:1120px) {
    .single-playlist_container_url {
        width: 50vw !important;
    }
}

@media screen and (max-width:940px) {
    .single-playlist_container_url {
        width: 20vw !important;
    }
}

@media screen and (max-width:585px) {
    .single-playlist {
        padding: 10px !important;

        .playlist {
            gap: 15px !important;
        }

        &_container {

            &_url {
                font-size: 16px;
            }
        }
    }

    .single-playlist_container_url {
        width: 20vw !important;
    }

    .name {
        font-weight: 500;
        font-size: 16px !important;
    }
}